




import {Component, Prop, Vue} from "vue-property-decorator";


@Component({})
export default class CircleImageButton extends Vue {
    @Prop(String) readonly src: string | undefined;
    @Prop(String) readonly type: string | undefined;
    @Prop(String) readonly size: string | undefined;

}
