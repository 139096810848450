<template>
  <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icons/Х</title>
    <g id="icons/Х" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M13,3 C7.47,3 3,7.47 3,13 C3,18.53 7.47,23 13,23 C18.53,23 23,18.53 23,13 C23,7.47 18.53,3 13,3 L13,3 Z M17.1311103,17.4588897 C16.8322633,17.7577367 16.3432448,17.7532448 16.0447563,17.4547563 L13,14.41 L9.95524373,17.4547563 C9.65411393,17.7558861 9.16439674,17.7543967 8.86888969,17.4588897 L8.54111031,17.1311103 C8.24226334,16.8322633 8.24675524,16.3432448 8.54524373,16.0447563 L11.59,13 L8.54524373,9.95524373 C8.24411393,9.65411393 8.24560326,9.16439674 8.54111031,8.86888969 L8.86888969,8.54111031 C9.16773666,8.24226334 9.65675524,8.24675524 9.95524373,8.54524373 L13,11.59 L16.0447563,8.54524373 C16.3458861,8.24411393 16.8356033,8.24560326 17.1311103,8.54111031 L17.4588897,8.86888969 C17.7577367,9.16773666 17.7532448,9.65675524 17.4547563,9.95524373 L14.41,13 L17.4547563,16.0447563 C17.7558861,16.3458861 17.7543967,16.8356033 17.4588897,17.1311103 L17.1311103,17.4588897 Z" id="Fill-209" fill="#788396"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CloseSvg"
}
</script>

<style scoped lang="scss">
  svg{
    cursor: pointer;
    &:hover{
      opacity: .7;
    }
  }
</style>