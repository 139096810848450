import { render, staticRenderFns } from "./CloseSvg.vue?vue&type=template&id=336768e8&scoped=true&"
import script from "./CloseSvg.vue?vue&type=script&lang=js&"
export * from "./CloseSvg.vue?vue&type=script&lang=js&"
import style0 from "./CloseSvg.vue?vue&type=style&index=0&id=336768e8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "336768e8",
  null
  
)

export default component.exports