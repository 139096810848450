<template>
  <div>
    <p>{{text.replaceAll(/\s/g,'') }}</p>
  </div>
</template>

<script>
export default {
  name: "ClassIcon",
  props:{
    text: String
  }
}
</script>

<style scoped>
  div {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #788396;
    border-radius: 50%;
  }
  p{
    font-family: "CodeNext";
    font-weight: 800;
    font-size: 11px;
    line-height: 14px;
    color: white;
    letter-spacing: 0;
    text-align: center;
  }
</style>
