
















import {Component, Vue} from "vue-property-decorator";
import CircleImageButton from "@/components/CircleImageButton.vue";
import {ActiveCard} from "@/entity/ActiveCard";
import {User} from "@/entity/User";
import moment from "moment";
import CloseSvg from "@/assets/vue-icons/Modal/CloseSvg.vue";

@Component({
  components: {CloseSvg, CircleImageButton}
})
export default class ConnectAutoPaymentModal extends Vue{

  private user:User = new User("","","","");
  private isNotOutSideClose: boolean = false;

  onBeforeOpen(e:any){
    this.isNotOutSideClose = false;
    this.user = e.params.user
  }

  onClose(){
    this.$modal.hide("ConnectAutoPaymentModal");
    this.$emit("close")
  }


  enableRecurrent(){
    this.onClose();
    this.isNotOutSideClose = true;
  }

  onClosed(){
    if (this.isNotOutSideClose){
      this.$emit("enable",this.user);
    } else {
      this.$emit("decline");
    }
  }

  toFormat(date : any){
    return moment(date.date).format("DD.MM.YYYY");
  }
}
