<template>
    <modal name="DeleteCardModalNew" width="308px" height="160px" @before-open="onBeforeOpen">
      <div class="modal">
        <CloseSvg class="modal__close" @click.native="showEditCardModal"/>
        <p class="modal__text">Вы уверены, что хотите отвязать карту?</p>
        <div class="modal__buttons">
          <button class="modal__button modal__button_cancel" @click="showEditCardModal">ОТМЕНА</button>
          <button class="modal__button modal__button_confirm" @click="deleteCard">ДА</button>
        </div>
      </div>
    </modal>
</template>

<script>
    import CloseSvg from "@/assets/vue-icons/Modal/CloseSvg";
    export default {
        name: "DeleteCardModalNew",
      components: {CloseSvg},
      data(){
          return {
              activeCard : null
          }
        },
        methods:{
            onBeforeOpen(e){
                this.activeCard = {...e.params.activeCard}
            },

            onClose(){
                this.$modal.hide("DeleteCardModalNew");
            },

            showEditCardModal(){
                this.onClose();
                this.$modal.show("EditCardModalNew",{value: this.activeCard})
            },

            deleteCard(){
                this.onClose();
                this.$emit("delete",this.activeCard);
            },
        }
    }
</script>

<style scoped lang="scss">

  .modal{
    background: #FFFFFF;
    border: 1px solid #EBEDEE;
    border-radius: 4px;
    width: 308px;
    height: 160px;
    display: flex;
    flex-direction: column;

    &__close{
      margin-top: 8px;
      margin-left: 274px;
    }

    &__text{
      margin-top: 11px;
      font-family: Roboto, "sans-serif";
      font-weight: 300;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      line-height: 24px;
      margin-left: 22px;
    }

    &__buttons{
      margin-top: 31px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    &__button{
      margin-right: 20px;

      &_confirm{
        width: 60px;
        height: 40px;
      }

      &_cancel{
        width: 100px;
        height: 40px;
      }

      background: #F9F9F9;
      border: 1px solid #EBEDEE;
      border-radius: 6px;
      font-family: Roboto, "sans-serif";
      font-weight: 900;
      font-size: 14px;
      color: #69748C;
      letter-spacing: 0.93px;
      text-align: center;
      cursor: pointer;

      &:hover{
        background: #F2f2f2;
      }
    }
  }
</style>