<template>
    <modal name="PaymentFailedModalNew" width="308px" height="auto" @before-open="onBeforeOpen" @closed="onExit">
      <div class="modal">
        <CloseSvg
            class="modal__close"
            @click.native="$modal.hide('PaymentSuccessModalNew')"
        />
        <p class="modal__title">Оплата не прошла</p>
        <p class="modal__text">{{message}}</p>
        <div class="modal__buttons">
          <button class="modal__button modal__button_confirm" @click="exit">ОК</button>
        </div>
      </div>
    </modal>
</template>

<script>
    import CloseSvg from "@/assets/vue-icons/Modal/CloseSvg";
    export default {
        name: "PaymentFailedModalNew",
      components: {CloseSvg},
      data() {
            return {
                message: ""
            }
        },
        methods: {
            onBeforeOpen(e) {
                if (e.params.cardHolderMessage == null || e.params.cardHolderMessage === ''){
                    e.params.cardHolderMessage = 'Платеж просрочен, обновите страницу'
                }
                this.message = e.params.cardHolderMessage
            },

            onExit(){
                this.$emit('exit')
            },

            exit(){
                this.$modal.hide('PaymentFailedModalNew')
            }

        }
    }
</script>

<style scoped lang="scss">
.modal{
  background: #FFFFFF;
  border: 1px solid #EBEDEE;
  border-radius: 4px;
  width: 308px;
  height: auto;
  display: flex;
  flex-direction: column;

  &__close{
    margin-top: 8px;
    margin-left: 274px;
  }

  &__title{
    margin-left: 30px;
    margin-top: 4px;
    font-family: "CodeNext";
    font-weight: 800;
    font-size: 22px;
    color: #CA4E4E;
  }

  &__text{
    font-family: Roboto, "sans-serif";
    font-weight: 300;
    font-size: 14px;
    color: #141213;
    line-height: 21px;
    margin-left: 30px;
    width: 244px;
  }

  &__buttons{
    margin-top: 27px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &__button{
    margin-right: 20px;

    &_confirm{
      width: 60px;
      height: 40px;
    }

    &_cancel{
      width: 100px;
      height: 40px;
    }

    background: #F9F9F9;
    border: 1px solid #EBEDEE;
    border-radius: 6px;
    font-family: Roboto, "sans-serif";
    font-weight: 900;
    font-size: 14px;
    color: #69748C;
    letter-spacing: 0.93px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;

    &:hover{
      background: #F2f2f2;
    }
  }
}
</style>